import _isObject from 'lodash/isObject';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import _transform from 'lodash/transform';

import parseISO from './parseISO';

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function difference(object, base) {
  function changes(object, base) {
    return _transform(object, (result, value, key) => {
      if (!_isEqual(value, base[key])) {
        result[key] = _isObject(value) && _isObject(base[key]) ? changes(value, base[key]) : value;
      }
    });
  }

  return changes(object, base);
}

/**
 * Check if string date is valid Date
 * @param  {String} dateString  input string date
 * @return {Boolean}            Returns a test result
 */
export function isValidDate(dateString) {
  if (_isEmpty(dateString)) {
    return false;
  }

  const date = new Date(dateString);

  return date instanceof Date && !isNaN(date) && date.getFullYear() > 1;
}

export const isToday = someDate => {
  const parsedDate = typeof someDate === 'string' ? parseISO(someDate) : new Date(someDate);
  const equalDate = typeof someDate === 'object' ? someDate : parsedDate;
  const today = new Date();

  return (
    equalDate.getDate() === today.getDate() &&
    equalDate.getMonth() === today.getMonth() &&
    equalDate.getFullYear() === today.getFullYear()
  );
};

export const getCurrentDateUTC = () => {
  const currentDate = new Date();

  const currentYear = `${currentDate.getFullYear()}`;

  const currentMonth =
    String(currentDate.getMonth()).length === 1
      ? `0${currentDate.getMonth() + 1}`
      : `${currentDate.getMonth() + 1}`;

  const currentDay =
    String(currentDate.getDate()).length === 1
      ? `0${currentDate.getDate()}`
      : `${currentDate.getDate()}`;

  const currentHour =
    String(currentDate.getHours()).length === 1
      ? `0${currentDate.getHours()}`
      : `${currentDate.getHours()}`;

  const currentMinutes =
    String(currentDate.getMinutes()).length === 1
      ? `0${currentDate.getMinutes()}`
      : `${currentDate.getMinutes()}`;

  const currentSeconds =
    String(currentDate.getSeconds()).length === 1
      ? `0${currentDate.getSeconds()}`
      : `${currentDate.getSeconds()}`;

  return `${currentYear}-${currentMonth}-${currentDay} ${currentHour}:${currentMinutes}:${currentSeconds}`;
};

export const dateFormatter = date => {
  const messageDate = new Date(date);
  const currentDate = new Date();
  if (messageDate.getDate() === currentDate.getDate()) {
    return 'Сегодня';
  }

  const splitDate = date.split(/[- :]/).filter((item, index) => index < 3);

  return splitDate
    .map(item => {
      if (item === '') {
        return item;
      }
      return Number(item);
    })
    .reverse()
    .join('.');
};

//добавление пробела в 4-х значных (и более) цифрах
export const addSpace = (nStr) => {
  nStr += '';
  let x = nStr.split('.');
  let x1 = x[0];
  let x2 = x.length > 1 ? '.' + x[1] : '';
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ' ' + '$2');
  }
  return x1 + x2;
};
//расчет общей суммы баллов по городам исключая текущий город
export const getSumCityPoints = (cityPoints, currentCity) => {
  if (cityPoints[0].city.name === currentCity) {
    return cityPoints
      .filter((city, idx) => idx !== 0)
      .reduce((acc, city) => acc + city?.points?.points?.confirmed, 0);
  }
  return cityPoints.reduce((acc, city) => acc + city?.points?.points?.confirmed, 0);
};

export const retryWithDelay = async (fn, retries, delay) => {
  let attempt = 0;

  while (attempt < retries) {

    try {
      console.log(`Attempt ${attempt + 1}: Executing function...`);

      return await fn();
    } catch (error) {
      attempt += 1;
      console.error(`Attempt ${attempt} failed:`, error);
      if (attempt < retries) {
        console.warn(`Retrying in ${delay}ms...`);
        await new Promise(resolve => setTimeout(resolve, delay));  // Задержка перед следующей попыткой
      }
    }
  }

  console.error('All retries failed'); // Просто логируем ошибку, не выбрасываем исключение
  return null;
};
