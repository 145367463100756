import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isThemeNewYear24, isThemeNewYear24Demo } from '../../../../state/modules/city/selectors';
import adventApi from '../../../Advent/api';
import { updateProfile } from '../../../../state/modules/user/actions';
import { setGlobalMessage } from '../../../../utils/setGlobalMessage';
import { getRoute, paths } from '../../../../entry/routes';
import qs from 'query-string';
import { getFormattedDate } from '../../../Advent/utils';
import { adventCalendarTaskStatus as adventTaskStatus, adventTabs } from '../../../Advent/useAdventData';
import { applyStock } from '../../../../state/modules/stock/actions';

export const adventCalendarTaskStatus = {
  complete: 'completed',
  active: 'active',
  disabled: 'expired',
  upcoming: 'disabled',
};

export const adventTaskType = {
  click: 'click',
  promocode: 'promocode',
  birthDate: 'birth_date',
  profile: 'profile',
  link: 'link',
  coupon: 'coupon',
  email: 'email',
  offline: 'offline',
  description: 'description',
  purchase: 'purchase',
};

const tokenParam = 'oftb64';


export const useFestiveTasks = ({ festiveId = 5, tasksCount = 0, onTaskComplete = () => {} }) => {
  const intl = useIntl();
  const history = useHistory();
  const { isAuthenticated } = useSelector(state => state.user);
  const profile = useSelector(state => state.user.profile);
  const dispatch = useDispatch();
  const location = useLocation();

  // Purchases / Written off points
  const [purchasesPoints, setPurchasesPoints] = useState(0);

  // isLoading
  const [isReady, setIsReady] = useState(false);

  // TASKS
  const [tasks, setTasks] = useState(() => Array(tasksCount).fill(null).map((_,i) => ({id: i+1})));


  // TRANSLATES
  const taskButtonTexts = useRef({
    [adventTaskType.link]: intl.formatMessage({ id: 'promo.advent.modal.task.btn' }),
    [adventTaskType.click]: intl.formatMessage({ id: 'promo.advent.task.complete' }),
    [adventTaskType.coupon]: intl.formatMessage({ id: 'promo.advent.task.complete' }),
    couponCompleted: intl.formatMessage({ id: 'promo.advent.task.applyCode' }),
    [adventTaskType.email]: intl.formatMessage({ id: 'promo.advent.task.email' }),
    [adventTaskType.birthDate]: intl.formatMessage({ id: 'promo.advent.task.birthDate' }),
    [adventTaskType.profile]: intl.formatMessage({ id: 'promo.advent.task.birthDate' }),
    [adventTaskType.offline]: intl.formatMessage({ id: 'promo.advent.modal.task.btn' }),
    [adventTaskType.description]: intl.formatMessage({ id: 'promo.advent.modal.task.btn' }),
    [adventTaskType.promocode]: intl.formatMessage({ id: 'promo.advent.task.applyCode' }),
  }).current;

  const successCopyMessage = useRef({
    [adventTaskType.link]: intl.formatMessage({ id: 'message.copy' }),
    [adventTaskType.coupon]: intl.formatMessage({ id: 'promoGame.popup.copy' }),
    default: intl.formatMessage({ id: 'message.copy' }),
  }).current;

  // CALLBACKS


  const getButtonText = useCallback(({ taskType, taskStatus, reward }) => {
    if (reward && reward.coupon) {
      return taskButtonTexts[adventTaskType.promocode]
    }

    switch (taskType) {
      case adventTaskType.coupon:
        return taskStatus === adventCalendarTaskStatus.complete ? taskButtonTexts.couponCompleted : taskButtonTexts[taskType]
      default:
        return taskButtonTexts[taskType];

    }
  },[taskButtonTexts])

  const getCallBack = useCallback(
    ({ taskType, id, promocode, link, taskPosition, status, reward }) => {
      if (reward && reward.coupon) {
        return applyPromoCode(reward.coupon)
      }
      switch (taskType) {
        case adventTaskType.click:
          return completeTaskHandler(id);
        case adventTaskType.email:
          return subscribeTaskHandler();
        case adventTaskType.link:
          return goToExternalLink(link);
        case adventTaskType.promocode:
          return applyPromoCode(promocode);
        case adventTaskType.birthDate:
          return goToProfileHandler();
        case adventTaskType.profile:
          return goToProfileHandler();
        case adventTaskType.coupon:
          return status === adventCalendarTaskStatus.active ?  completeTaskHandler(id) : applyPromoCode(promocode);
        case adventTaskType.offline:
          return goToExternalLink(link);
        case adventTaskType.description:
          return goToExternalLink(link);
        case adventTaskType.purchase:
          return completeTaskHandler(id);
        default:
          return null;
      }
    },
    [successCopyMessage],
  );



  const completeTaskHandler = useCallback(
    async (id) => {
        try {
          const response = await adventApi.taskCheckpoint(id);

          // @TODO проверить что в респонсе и засетать
          setTasks(prevState =>
            prevState.map(task => {

              if (task.realId !== id) return task;

              if (task.type === adventTaskType.purchase) {
                setPurchasesPoints(prevState => prevState + response?.count)
              }

              return {
                ...task,
                status: adventCalendarTaskStatus.complete,
                promocode: task.type === adventTaskType.coupon && response?.coupon,
                reward: response,
                btnText: getButtonText({ reward: response, taskType: task.type, taskStatus: adventTaskStatus.complete }),
                cb: () => getCallBack({
                    reward: response,
                    taskType: task.type,
                    status: adventTaskStatus.complete,
                    link: task.link ?? '',
                    id: task.realId,
                    promocode: task.type === adventTaskType.coupon ? response?.coupon : task.promocode ?? '',
                    taskPosition: task.id ?? '',
                  },
                ),
              }
            }),
          );
          if (onTaskComplete) {
            onTaskComplete(response)
          }
        } catch (e) {
          console.error('Error completing task:', error);
        }
    },
    [setPurchasesPoints, setTasks, getButtonText,getCallBack, onTaskComplete ],
  );

  const applyPromoCode = useCallback(async (promoCode) => {
    try {
      await dispatch(applyStock({ stock_code: promoCode }));
      history.push(getRoute(paths.home))
    } finally {}

  },[dispatch, history])

  const subscribeTaskHandler = useCallback(
    async () => {
      try {
        await dispatch(updateProfile({ subscription_state: true }));
      } finally {
      }

    },
    [dispatch, completeTaskHandler],
  );

  const onCopyHandler = useCallback(
    async (text, successMsg = successCopyMessage.default) => {
      await navigator.clipboard.writeText(text);
      setGlobalMessage(successMsg, { type: 'success' });
    },
    [setGlobalMessage, successCopyMessage],
  );

  const goToProfileHandler = useCallback(() => {
    history.push(getRoute(paths.profile));
  }, [history]);

  const goToExternalLink = useCallback(link => {
    window.open(link, '_blank', 'noopener,noreferrer');
  }, []);


  // ВЫПОЛНЕНИЕ ЗАДАНИЯ ПО ССЫЛКЕ С ТОКЕНОМ
  useEffect(() => {
    const queryToken = qs.parse(location.search)[tokenParam]

    if (queryToken && isReady){
      const relatedForTokenTask = tasks.find(t => t.token === queryToken);

      if (relatedForTokenTask && isAuthenticated) {
        if (relatedForTokenTask.status === adventCalendarTaskStatus.active) {
          completeTaskHandler(relatedForTokenTask.realId).then(() => {
            history.replace({ search: '' });
          }) ;
        } else {
          history.replace({ search: '' });
        }
      }

    }

  }, [isReady, setIsReady, history, tasks, qs, location]);

  // Выполнение заданий подписки и даты рождения при входе на страницу
  useEffect(() => {
    if (isAuthenticated && tasks) {
      const bDateTask = tasks.find(task => task.type === adventTaskType.birthDate);
      const subscribeTask = tasks.find(task => task.type === adventTaskType.email);
      const profileTask = tasks.find(task => task.type === adventTaskType.profile);
      if (
        bDateTask &&
        bDateTask.status === adventCalendarTaskStatus.active &&
        profile?.birth_date
      ) {
        completeTaskHandler(bDateTask.realId);
      }
      if (
        subscribeTask &&
        subscribeTask.status === adventCalendarTaskStatus.active &&
        profile?.subscription_state
      ) {
        completeTaskHandler(subscribeTask.realId);
      }
      if (
        profileTask &&
        profileTask.status === adventCalendarTaskStatus.active &&
        profile?.birth_date &&
        profile?.gender !==0
      ) {
        completeTaskHandler(profileTask.realId);
      }
    }

  }, [isAuthenticated, tasks, profile]);

  const getTaskDescription = useCallback((date, status, description) => {
    const taskClosedText = intl.formatMessage(
      { id: 'promo.advent.modal.taskClosed.text' },
      { content: `<b>${date}</b>` },
    );
    const taskExpiredText = intl.formatMessage({ id: 'promo.advent.modal.taskExpired.text' });

    if (status === adventCalendarTaskStatus.active) {
      return description;
    }
    if (status === adventCalendarTaskStatus.upcoming) {
      return taskClosedText;
    }

    if (status === adventCalendarTaskStatus.disabled) {
      return taskExpiredText;
    }
    return description;
  }, []);


  // ЗАПОЛНЯЕМ задания описаниями полученными с бэка
  useEffect(() => {
    const fetchData = async () => {
      await setIsReady(false);

      try {
        const fetchedTasks = await adventApi.getAdventTasks(festiveId).then(data => data);

        const updatedTiles = fetchedTasks.map((task, index) => {
          const taskAvailableDate = getFormattedDate(
            fetchedTasks[index]?.date ?? '2023-12-31T05:00:00.000',
          );
          const taskClosedText = intl.formatMessage(
            { id: 'promo.advent.modal.taskClosed.text' },
            { content: `<b>точно до ${taskAvailableDate}</b>` },
          );

          const isTaskActiveOrComplete =
            fetchedTasks[index]?.status === adventCalendarTaskStatus.active ||
            fetchedTasks[index]?.status === adventCalendarTaskStatus.complete;
          if (fetchedTasks[index]) {
            return {
              ...fetchedTasks[index],
              id: index + 1,
              realId: fetchedTasks[index].id,
              status: fetchedTasks[index].status ?? adventCalendarTaskStatus.upcoming,
              date: taskAvailableDate,
              type: fetchedTasks[index].type ?? adventTaskType.click,
              short_description: getTaskDescription(
                taskAvailableDate,
                fetchedTasks[index].status,
                fetchedTasks[index].short_description,
              ),
              description: getTaskDescription(
                taskAvailableDate,
                fetchedTasks[index].status,
                fetchedTasks[index].description,
              ),
              promocode: isTaskActiveOrComplete ? fetchedTasks[index].promocode || fetchedTasks[index].coupon : '',
              link: isTaskActiveOrComplete ? fetchedTasks[index].link : '',
              tickets: task.tickets ?? [],
              cb: () =>
                getCallBack({
                    reward: fetchedTasks[index].reward ?? {},
                    taskType: fetchedTasks[index].type,
                    status: fetchedTasks[index].status ?? '',
                    link: fetchedTasks[index].link ?? '',
                    id: fetchedTasks[index].id,
                    promocode: fetchedTasks[index].promocode ?? fetchedTasks[index].coupon ?? '',
                    taskPosition: task.id ?? '',
                  },
                ),
              btnText: isTaskActiveOrComplete ? getButtonText({ reward: fetchedTasks[index].reward, taskType: fetchedTasks[index].type, taskStatus: fetchedTasks[index].status}) : ''
            };
          }
          return {
            id: task.id,
            realId: task.id,
            status: adventCalendarTaskStatus.upcoming,
            date: taskAvailableDate,
            type: adventTaskType.click,
            short_description: taskClosedText,
            description: taskClosedText,
            promocode: '',
            link: '',
            tickets: [],
            cb: null,
            btnText: '',
          };
        });
        setTasks(updatedTiles);
      } finally {
        setIsReady(true);
      }
    };

    if (isAuthenticated && festiveId) {
      fetchData().finally(() => {});
    } else {
      setTasks(Array(tasksCount).fill(null).map((_,i) => ({id: i+1})))
    }

  }, [setTasks, setIsReady, isAuthenticated, festiveId]);

  const userRewards = useMemo(() => {
    if (tasks.length) {
      const completedTasks = tasks.filter(task => task?.reward);
      if (completedTasks.length) {
        return completedTasks
          .sort(t => t?.date)
          .map((task) => ({
            ...task?.reward,
            date:
              getFormattedDate(task?.reward?.date, {
                month: 'numeric',
                day: 'numeric',
              }) ?? '',
          }));
      }
    }
    return [];
  }, [tasks]);

  const collectedRewardCount = useMemo(() => {
    return userRewards.reduce((total, task) => {return total + Number(task.count)}, 0);
  },[userRewards])

  return {
    isReady,
    tiles: tasks,
    userRewards,
    collectedRewardCount
  };
};

