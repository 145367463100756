import cityApi from './api';
import * as types from './actionTypes';

// Utils
import isomorphicCookies from '../../../utils/cookie/isomorphicCookies';

// Config
import config from '../../../config';

export const fetchCities = params => {
  return dispatch => {
    dispatch({ type: types.FETCH_CITIES_REQUEST });

    return cityApi
      .getCities(params)
      .then(data => {
        dispatch({ type: types.FETCH_CITIES_SUCCESS, payload: data });
        return data;
      })
      .catch(({ message }) => {
        console.error(`Failed to fetch city/list. Reason: ${message}`);
        dispatch({ type: types.FETCH_CITIES_FAILURE });
        throw message;
      });
  };
};

export const fetchUserCity = params => {
  return dispatch => {
    dispatch({ type: types.FETCH_USER_CITY_REQUEST });

    const { lang, city_id, headers, ip } = params;
    const newParams = { lang };

    // запрашиваем данные по городу из куки city_id, если кука не установлена и city_id = undefined
    // то город придет исходя из ip адреса в хедере запроса
    if (city_id) {
      newParams.city_id = city_id;
    }

    // нужно для опрпделения текущего города по ip
    return cityApi
      .getUserCity(newParams, { 'X-Forwarded-For': headers['x-real-ip'] || ip })
      .then(data => {
        dispatch({ type: types.FETCH_USER_CITY_SUCCESS, payload: data[0] });
        return data[0];
      })
      .catch(({ message }) => {
        console.error(message);
        return dispatch({ type: types.FETCH_USER_CITY_FAILURE });
      });
  };
};

export const setUserCity = cityId => {
  return dispatch => {
    dispatch({ type: types.SET_USER_CITY, payload: cityId });
  };
};

export const searchCities = query => {
  return dispatch => {
    dispatch({ type: types.SEARCH_CITIES, payload: query });
  };
};

export const checkCityAllowed = (cityId, res) => {
  return (_, getState) => {
    // ищем среди всего списка город по id, который к тому же не входит в список доступных
    const redirectCity = getState().city.list.find(
      city => city.id === cityId && city.is_allowed === 0,
    );

    if (redirectCity) {
      let newLocal = `https://papajohns.${config.legal || 'ru'}`;

      if (redirectCity._supertag) {
        newLocal = `https://${redirectCity._supertag}.papajohns.${config.legal || 'ru'}`;
      }

      // выполняем редирект с сервера
      if (res) {
        res.redirect(newLocal);
        res.end();
      } else {
        // или с клиента
        window.location.assign(newLocal);
      }

      return false;
    }

    return true;
  };
};

export const selectUserCity = city => {
  return dispatch => {
    const { pathname, origin, search } = window.location;

    if (!city?.id) {
      return;
    }

    const isCityAllowed = dispatch(checkCityAllowed(city.id));

    if (isCityAllowed) {
      isomorphicCookies().set('city_id', city.id);
      isomorphicCookies().remove('delivery_alert_is_shown');
      isomorphicCookies().set('city_confirm', '1', { expires: 7 });

      // если у этого города есть супертег, то делаем урл с городом
      const oldPathname = pathname.split('/');
      oldPathname[1] = city._supertag;
      const newPathname = oldPathname.join('/');

      window.location.assign(`${origin}${newPathname}${search}`);
    }
  };
};

export const fetchCityCoordinates = (city) => {
  return dispatch => {
    dispatch({ type: types.FETCH_USER_CITY_REQUEST });

    isomorphicCookies().set('city_id', city.id);

    // нужно для опрпделения текущего города по ip
    return cityApi
      .getUserCoordinates()
      .then(data => {
        return dispatch({ type: types.FETCH_USER_CITY_SUCCESS, payload: data });
      })
      .catch(error => {
        console.error(`Failed to fetch user city. Reason: ${error}`);
        dispatch({ type: types.FETCH_USER_CITY_FAILURE });
        throw error;
      });
  };
};

export const fetchGeoPosition = (coordinates) => {
  return dispatch => {
    dispatch({ type: types.FETCH_CURRENT_GEO_POSITION_REQUEST });

    return cityApi
      .getGeoPosition(coordinates[0], coordinates[1])
      .then(data => {
        return dispatch({
          type: types.FETCH_CURRENT_GEO_POSITION_SUCCESS,
          payload: data?.response.GeoObjectCollection.featureMember[0].GeoObject.metaDataProperty.GeocoderMetaData.Address.formatted
        });
      })
      .catch(({ message }) => {
        console.error(message);
        return dispatch({ type: types.FETCH_CURRENT_GEO_POSITION_FAILURE });
      });
  };
};

export const setWaitOpenings = () => ({ type: types.SET_WAIT_OPENINGS_CITY });
